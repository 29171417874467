import React from "react";
import { useEffect, useState } from "react";
import { Col, Container, Row, Table, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
function Month(props) {
  const [calls, setCalls] = useState("");
  useEffect(() => {}, []);
  return (
    <tr>
      <td style={{ verticalAlign: "middle" }}>
        <strong>{props.month}, {props.year}</strong><br />
        {props.agenda?        
        <Button variant="warning" style={{ marginBottom: "5px" }}
        onClick={() => window.open(props.agenda, "_blank")}>
          Agenda
        </Button> :
        null
        }
        <br />
      </td>
      {/* <td>
      {props ? <div>
        
      {props.presentations.map((data) => (
        <Button
          style={{ width: "100%", marginBottom: "5px" }}
          onClick={() => window.open(data.link, "_blank")}
        >
          {data.name}
        </Button>
      ))}
      </div>
      :
      null
      }
        
      </td> */}
      <td>
        {props.handouts.map((data) => (
          <Button
            variant="info"
            style={{ width: "100%", marginBottom: "5px" }}
            onClick={() => window.open(data.link, "_blank")}
          >
            {data.name}
          </Button>
        ))}
      </td>
    </tr>
  );
}

export default Month;
