import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Table,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Month from "./components/Month";

function App() {
  const [calls, setCalls] = useState([]);
  const [passcode, setPasscode] = useState("");
  const [unlocked, setUnlocked] = useState(false);
  useEffect(() => {
    fetch("./api/getCalls.php")
      .then((res) => res.json())
      .then((data) => setCalls(data));
  }, [unlocked]);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  
  // console.log(urlParams.get('id'));
  
  useEffect(()=>{
    if(urlParams.get('passcode')!==null){
      console.log(urlParams.get('passcode'))
    fetch("./api/checkCode.php",{
      method: "POST",
      body: JSON.stringify({ code: urlParams.get('passcode') }),
    })
    .then((res)=>res.json())
    .then((data)=>(
      data ? setUnlocked(true) : setUnlocked(false)
    ));
    }
  },[])
  const unlockFunction = () => {
    fetch("./api/checkCode.php", {
      method: "POST",
      body: JSON.stringify({ code: passcode }),
    })
      .then((res) => res.json())
      .then((res) => (res ? setUnlocked(true) : setUnlocked(false)));
    setPasscode("");
  };
  const handlePasscodeChange = (event) => {
    setPasscode(event.target.value);
    // console.log(passcode);
  };
  return (
    <div className="App">
      <div style={{ backgroundColor: "rgba(255,255,255,.8)" }}>
        <Container>
          <Row>
            <Col>
              <h1>NTCP Webinars</h1>
              {unlocked ? (null) : (
              <InputGroup
                className="mb-3"
                style={{
                  position: "fixed",
                  top: 10,
                  right: 10,
                  maxWidth: 250,
                }}
              >                
                <FormControl
                  placeholder="Enter passcode"
                  aria-label="Enter passcode"
                  aria-describedby="basic-addon2"
                  type="password"
                  onChange={handlePasscodeChange}
                  value={passcode}
                />
                <InputGroup.Append>
                  <Button variant="success" onClick={() => unlockFunction()}>
                    Unlock
                  </Button>
                </InputGroup.Append>
              </InputGroup>
         
                )}
            </Col>
          </Row>
        </Container>
      </div>
      {unlocked ? (
        <div className="table-container">
          <Table
            striped
            responsive
            style={{
              tableLayout: "fixed",
            }}
          >
            <thead>
              <tr>
                <th style={{ width: "20%" }}>Month</th>
                {/* <th style={{ maxWidth: "500px" }}>Recording/Presentations</th> */}
                <th style={{ maxWidth: "500px" }}>Handouts</th>
              </tr>
            </thead>
            <tbody>
              {calls !== []
                ? calls.map((data) => (
                    <Month
                      month={data.month}
                      year={data.year}
                      presentations={data.presentations}
                      handouts={data.handouts}
                      recording={data.recording}
                      agenda={data.agenda}
                    />
                  ))
                : null}
              {/* // <Month month={"October"} />
            // <Month month={"November"} />
            // <Month month={"December"} /> */}
            </tbody>
          </Table>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "calc(100vh - 56px)",
          }}
        >
          <div
            style={{
              // width: "500px",
              // height: "500px",
              maxWidth: "500px",
              backgroundColor: "white",
              padding: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div>
              <h2 style={{ color: "red" }}>Locked</h2>
              Please enter the passcode that was included in the weekly dose in
              order to unlock and gain access to all available NTCP Webinar
              recordings as well as additional presentations and handouts.
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
